import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Helmet } from 'react-helmet-async'
import { withPreview } from 'gatsby-source-prismic'
import MapSlicesToComponents from '@walltowall/react-map-slices-to-components'

import { MenuItemTemplateQuery } from '../types.generated'
import { PickPartial } from '../types'
import { MapDataToPropsEnhancerArgs } from '../lib/mapSlicesToComponents'
import { pairsEq } from '../lib/pairsEq'
import { slicesMap } from '../slices/PageBody'

import { Layout } from '../components/Layout'
import { useSiteSettings } from '../hooks/useSiteSettings'

/**
 * `mapDataToPropsEnhancer` for `react-map-slices-to-components`. Props defined
 * here are added to all slices.
 *
 * @see https://github.com/WalltoWall/react-map-slices-to-components#providing-global-enhancers
 */
export const mapDataToPropsEnhancer = (
  props: Record<string, unknown> | undefined,
  {
    context,
    previousContext = {},
    nextContext = {},
    previousType,
    nextType,
    previousData,
  }: MapDataToPropsEnhancerArgs,
) => ({
  id:
    previousType === 'PageBodyAnchor'
      ? (previousData?.primary?.id as string)
      : undefined,
  previousSharesBg: pairsEq(
    previousContext.bgBottom ?? previousContext.bg,
    context.bgTop ?? context.bg,
  ) as boolean | boolean[],
  previousOverhangs: previousContext.overhangsBottom as boolean | boolean[],
  previousIsHeader: previousType === 'PageBodyHeader',
  nextSharesBg: pairsEq(
    context.bgBottom ?? context.bg,
    nextContext.bgTop ?? nextContext.bg,
  ) as boolean | boolean[],
  nextOverhangs: nextContext.overhangsTop as boolean | boolean[],
  nextIsFooter: nextType === 'PageBodyFooter',
  ...props,
})

/**
 * Props added to all slices by `mapDataToPropsEnhancer` for `MenuItemTemplate`.
 * Intersect this type with a slice's known props to get a complete list of
 * available props.
 *
 * @see https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html#intersection-types
 */
export type MenuItemTemplateEnhancerProps = PickPartial<
  ReturnType<typeof mapDataToPropsEnhancer>,
  // Props defined in the enhancer function are marked as optional to remove
  // their necessity when using slices as React components.
  | 'id'
  | 'previousOverhangs'
  | 'previousSharesBg'
  | 'nextOverhangs'
  | 'nextSharesBg'
>

export const MenuItemTemplate = ({
  data,
  location,
}: PageProps<MenuItemTemplateQuery>) => {
  const siteSettings = useSiteSettings()
  const menuItem = data?.prismicMenuItem

  /**
   * `listMiddleware` for `react-map-slices-to-components`. Add or modify slices
   * for the page here.
   *
   * @see https://github.com/WalltoWall/react-map-slices-to-components#change-the-list-of-slices
   */
  const slicesMiddleware = <T,>(list: T[]) => [
    { __typename: 'PageBodyHeader', id: 'header' },
    {
      __typename: 'PageBodyHeroMenuItem',
      id: 'hero',
      primary: {
        subheading: { text: 'Food' },
        heading: { text: menuItem?.data?.title?.text },
        image: {
          alt: menuItem?.data?.featured_image?.alt,
          fluid: menuItem?.data?.featured_image?.fluid,
        },
      },
    },
    {
      __typename: 'PageBodyText',
      id: 'description',
      primary: {
        text: {
          text: menuItem?.data?.description?.text,
          html: menuItem?.data?.description?.html,
        },
      },
    },
    {
      __typename: 'PageBodyNutritionFacts',
      id: 'nutrition_facts',
      primary: {
        calories: { text: menuItem?.data?.calories },
        calories_from_fat: { text: menuItem?.data?.calories_from_fat },
        total_fat: { text: menuItem?.data?.total_fat },
        saturated_fat: { text: menuItem?.data?.saturated_fat },
        cholesterol: { text: menuItem?.data?.cholesterol },
        sodium: { text: menuItem?.data?.sodium },
        total_carbohydrates: { text: menuItem?.data?.total_carbohydrates },
        dietary_fiber: { text: menuItem?.data?.dietary_fiber },
        sugar: { text: menuItem?.data?.sugar },
        protein: { text: menuItem?.data?.protein },
        disclaimer: {
          text: siteSettings.nutritionFactsDisclaimerText,
          html: siteSettings.nutritionFactsDisclaimerHTML,
        },
      },
    },
    ...list,
    { __typename: 'PageBodyFooter', id: 'footer' },
  ]

  /**
   * Metadata made available in a slice's `mapDataToProps` and
   * `mapDataToContext` functions.
   *
   * @see https://github.com/angeloashmore/react-map-to-components#maptocomponents
   */
  const meta = React.useMemo(
    () => ({
      rootData: data,
      location,
    }),
    [data, location],
  )

  return (
    <Layout>
      <Helmet>
        <title>
          {menuItem?.data?.title?.text ?? ''} | {siteSettings.siteName}
        </title>
        {menuItem?.data?.description && (
          <meta name="description" content={menuItem.data.description.text} />
        )}
      </Helmet>
      <MapSlicesToComponents
        list={[]}
        map={slicesMap}
        meta={meta}
        listMiddleware={slicesMiddleware}
        mapDataToPropsEnhancer={mapDataToPropsEnhancer}
      />
    </Layout>
  )
}

export default withPreview(MenuItemTemplate)

export const query = graphql`
  query MenuItemTemplate($id: String!) {
    prismicMenuItem(id: { eq: $id }) {
      _previewable
      data {
        title {
          text
        }
        description {
          text
          html
        }
        featured_image {
          alt
          fluid(maxWidth: 500) {
            ...GatsbyPrismicImageFluid
          }
        }
        calories
        calories_from_fat
        total_fat
        saturated_fat
        cholesterol
        sodium
        total_carbohydrates
        dietary_fiber
        sugar
        protein
      }
    }
  }
`
